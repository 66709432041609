<script>
  import {
    required,
    email,
    helpers
  } from "@vuelidate/validators";
  import appConfig from "../../../app.config";
import axios from 'axios';
  export default {
    page: {
      title: "Login",
      meta: [{
        name: "description",
        content: appConfig.description,
      }, ],
    },
    data() {
      return {
        
        email: "",
        password: "",
        submitted: false,
        authError: null,
        tryingToLogIn: false,
        isAuthError: false,
      };
    },
    validations: {
      email: {
        required: helpers.withMessage("Email is required", required),
        email: helpers.withMessage("Please enter valid email", email),
      },
      password: {
        required: helpers.withMessage("Password is required", required),
      },
    },
    computed: {

    },
    created() {
      this.checklogged();
    },
    methods: {
        async checklogged() {

  await axios.get(process.env.VUE_APP_MAIN_URL+ "/auth/me").then((data) => {




    localStorage.setItem('userdata', JSON.stringify(data.data.data))


     this.$store.commit('setUser', {
      token: localStorage.getItem('accessToken') ,
      refresh: localStorage.getItem('accessToken') ,
      user:  data.data.data,
    
} )  
  
    this.$router.push({name: 'dashboard'});


  }).catch(() => {
    this.$store.commit('SetAuthLogout')
   
 
  });




        },
      async signinapi() {

       this.tryingToLogIn = true;
try {
  
       
  var data = await this.$store.dispatch('login', {email: this.email,password: this.password});  

     this.tryingToLogIn = false;
   this.$router.push({name: 'dashboard'});
  if (data.status == false) {
    //  return this.authError == data.message;

  }            


       
} catch (error) {


    this.tryingToLogIn = false;
    this.isAuthError = true

    var status = error.response.status;



    if (status == 422) {
       var dataa = JSON.parse(error.response.data.data)
    var html = [];
    this.load = false;
    for (var k in dataa) {
        if (Object.prototype.hasOwnProperty.call(dataa, k)) {
               html.push(dataa[k][0]);
       

        }
    }
    
      return this.authError = html;

    }  else if(status == 401) {
         var htmll = [];
      var errors = error.response.data.data.message;
       htmll.push(errors);
       return this.authError = htmll;



    }
 
 
 
}



       
      }
    },
  };
</script>

<template>
  <div class="auth-page-wrapper pt-5">
    <!-- auth page bg -->
    <div class="auth-one-bg-position auth-one-bg" id="auth-particles">
      <div class="bg-overlay"></div>

      <div class="shape">
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 1440 120">
          <path d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8
            1440,40L1440 140L0 140z"></path>
        </svg>
      </div>
    </div>

    <!-- auth page content -->
    <div class="auth-page-content">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center mt-sm-5 mb-4 text-white-50">
              <div>
                <router-link to="/" class="d-inline-block auth-logo">
                  <img src="@/assets/images/logo-light.png" alt="" height="40" />
                </router-link>
              </div>
              <p class="mt-3 fs-15 fw-medium">
                Best bill payment in nigeria
              </p>
            </div>
          </div>
        </div>
        <!-- end row -->

        <div class="row justify-content-center">
          <div class="col-md-8 col-lg-6 col-xl-5">
            <div class="card mt-4">
              <div class="card-body p-4">
                <div class="text-center mt-2">
                  <h5 class="text-primary">Welcome Back !</h5>
                  <p class="text-muted">Sign in to continue to Gopay.</p>
                </div>
                <div class="p-2 mt-4">
                  <b-alert v-model="authError" variant="danger" class="mt-3" dismissible><ul><li v-for="data in authError " :key="data">
            {{ data }}
     </li></ul></b-alert>

                  <div>

                  </div>

                  <form @submit.prevent="tryToLogIn">
                    <div class="mb-3">
                      <label for="email" class="form-label">Email</label>
                      <input type="email" class="form-control" id="email" placeholder="Enter email" v-model="email" />
                      <div class="invalid-feedback">
                        <span></span>
                      </div>
                    </div>

                    <div class="mb-3">
                      <div class="float-end">
                        <router-link to="/forgot-password" class="text-muted">Forgot
                          password?</router-link>
                      </div>
                      <label class="form-label" for="password-input">Password</label>
                      <div class="position-relative auth-pass-inputgroup mb-3">
                        <input type="password" v-model="password" class="form-control pe-5" placeholder="Enter password"
                          id="password-input" />
                        <button class="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                          type="button" id="password-addon">
                          <i class="ri-eye-fill align-middle"></i>
                        </button>
                        <div class="invalid-feedback">
                          <span></span>
                        </div>
                      </div>
                    </div>

                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" value="" id="auth-remember-check" />
                      <label class="form-check-label" for="auth-remember-check">Remember
                        me</label>
                    </div>

                    <div class="mt-4">
                      <button class="btn btn-success w-100" type="submit" @click="signinapi" :disabled="tryingToLogIn">
                      <div class="spinner-border spinner-border-sm" role="status" v-if="tryingToLogIn"> 
  <span class="sr-only">Loading...</span>
</div>
                        Sign In
                      </button>
                    </div>

                   
                  </form>
                </div>
              </div>
              <!-- end card body -->
            </div>
            <!-- end card -->

            <div class="mt-4 text-center">
              <p class="mb-0">
                Don't have an account ?
                <router-link to="/register" class="fw-semibold text-primary
                  text-decoration-underline">
                  Signup
                </router-link>
              </p>
            </div>
          </div>
        </div>
        <!-- end row -->
      </div>
      <!-- end container -->
    </div>
    <!-- end auth page content -->

    <!-- footer -->
    <footer class="footer">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center">
              <p class="mb-0 text-muted">
                &copy; {{ new Date().getFullYear() }} Gopay. Crafted with
                <i class="mdi mdi-heart text-danger"></i> by Go Softwares
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <!-- end Footer -->
  </div>
  <!-- end auth-page-wrapper -->
</template>